import React, { useState, useEffect } from 'react';
import { Download, Camera } from 'lucide-react';

const ImageGallery = ({ selectedDate, user }) => {
  const [images, setImages] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const apiKey = "HWN2M2mcbZoyFDTfpaskQV9TBDk0Ir8bu4XWDxJQiBE";
  const apiBaseUrl = "https://www.teleport.io/api/v2";

  useEffect(() => {
    if (!user?.feedID || !selectedDate) {
      setError("Missing required feed ID or date");
      setIsLoading(false);
      return;
    }
    fetchImages();
  }, [selectedDate, user?.feedID]);

  const fetchImages = async () => {
    try {
      setIsLoading(true);
      setError(null);
      
      const startTime = `${selectedDate.toISOString().split('T')[0]}T00:00:00Z`;
      const endTime = `${selectedDate.toISOString().split('T')[0]}T23:59:59Z`;
      const queryUrl = `${apiBaseUrl}/frame-query?feedid=${user.feedID}&starttime=${startTime}&endtime=${endTime}&interval=3600&apikey=${apiKey}`;
      
      const response = await fetch(queryUrl);
      if (!response.ok) {
        throw new Error(`Error al cargar imágenes: ${response.status}`);
      }
      
      const data = await response.json();
      if (!data.Frames || !Array.isArray(data.Frames)) {
        throw new Error("Formato de datos inválido");
      }

      const imageUrls = data.Frames.map(frameTime => ({
        url: `${apiBaseUrl}/frame-get?feedid=${user.feedID}&frametime=${frameTime}&sizecode=1080p&apikey=${apiKey}`,
        timestamp: frameTime
      }));
      
      setImages(imageUrls);
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const downloadImage = async (imageUrl, timestamp) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `image-${new Date(timestamp).toISOString()}.jpg`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading image:', error);
    }
  };

  const formatDateToSpanish = (date) => {
    const days = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    return `${days[date.getDay()]}, ${date.getDate()} de ${months[date.getMonth()]} de ${date.getFullYear()}`;
  };

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center p-8 bg-gray-50 rounded-lg">
        <div className="text-red-500 text-center">
          <p className="font-semibold mb-2">Error</p>
          <p>{error}</p>
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center p-8 bg-gray-50 rounded-lg">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mb-4"></div>
        <p className="text-gray-600">Cargando imágenes...</p>
      </div>
    );
  }

  if (images.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center p-8 bg-gray-50 rounded-lg">
        <Camera className="w-16 h-16 text-gray-400 mb-4" />
        <h3 className="text-xl font-semibold text-gray-600 mb-2">No hay fotos disponibles</h3>
        <p className="text-gray-500 text-center">
          No se encontraron fotos para el día {formatDateToSpanish(selectedDate)}
        </p>
      </div>
    );
  }

  return (
    <div>
      <div className="mb-4">
        Se han encontrado {images.length} imágenes para el día {formatDateToSpanish(selectedDate)}
      </div>
      <div className="mb-4 font-bold">Atención! Espere unos segundos a que la imagen cargue.</div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        {images.map((image, index) => (
          <div key={index} className="relative group">
            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
              <Download 
                className="text-white w-12 h-12 cursor-pointer" 
                onClick={() => downloadImage(image.url, image.timestamp)}
              />
            </div>
            <div className="absolute top-2 left-2 bg-white bg-opacity-70 p-1 rounded text-xs">
              {new Date(image.timestamp).toLocaleTimeString()}
            </div>
            <img 
              src={image.url} 
              alt={`Image ${index + 1}`} 
              className="w-full h-full object-cover rounded-xl"
              loading="lazy"
              onError={(e) => {
                e.target.src = 'https://via.placeholder.com/150?text=Error+de+carga';
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageGallery;