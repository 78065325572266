import React, { useState, useEffect, useMemo } from 'react';
import { Camera } from 'lucide-react';

const Calendar = ({ onDateSelect, user }) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [daysWithPhotos, setDaysWithPhotos] = useState(new Set());
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiKey = "HWN2M2mcbZoyFDTfpaskQV9TBDk0Ir8bu4XWDxJQiBE";
  const apiBaseUrl = "https://www.teleport.io/api/v2";

  const months = useMemo(() => [
    'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
    'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
  ], []);

  const calendarInfo = useMemo(() => {
    const daysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getDay();
    return { daysInMonth, firstDayOfMonth };
  }, [currentDate]);

  useEffect(() => {
    if (!user?.feedID) {
      setError("Feed ID no disponible");
      setIsLoading(false);
      return;
    }
    
    const fetchPhotoDays = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        const endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0, 23, 59, 59);
        
        const queryUrl = `${apiBaseUrl}/frame-query?` +
          `feedid=${user.feedID}&` +
          `starttime=${startOfMonth.toISOString()}&` +
          `endtime=${endOfMonth.toISOString()}&` +
          `interval=3600&` +
          `apikey=${apiKey}`;

        const response = await fetch(queryUrl);
        if (!response.ok) {
          throw new Error('Error al cargar datos del calendario');
        }

        const data = await response.json();
        if (!data.Frames || !Array.isArray(data.Frames)) {
          throw new Error('Formato de datos inválido');
        }

        // Process all frames at once and get unique days
        const uniqueDays = new Set(
          data.Frames.map(timestamp => new Date(timestamp).getDate())
        );

        setDaysWithPhotos(uniqueDays);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPhotoDays();
  }, [currentDate, user?.feedID, apiBaseUrl]);

  const handleDateClick = (date) => {
    if (!date) return;
    setSelectedDate(date);
    onDateSelect(date);
  };

  const renderDays = () => {
    const days = [];
    const { daysInMonth, firstDayOfMonth } = calendarInfo;

    // Add empty cells for days before the first day of the month
    for (let i = 0; i < firstDayOfMonth; i++) {
      days.push(<div key={`empty-${i}`} className="p-2"></div>);
    }
    
    // Add cells for each day of the month
    for (let i = 1; i <= daysInMonth; i++) {
      const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), i);
      const hasPhotos = daysWithPhotos.has(i);
      const isSelected = selectedDate && 
        selectedDate.getDate() === i && 
        selectedDate.getMonth() === currentDate.getMonth() && 
        selectedDate.getFullYear() === currentDate.getFullYear();
      
      days.push(
        <div
          key={i}
          className={`
            relative border-solid border-2 rounded-md p-2 text-center cursor-pointer
            transition-all duration-200 ease-in-out
            hover:bg-blue-100
            ${isSelected ? 'bg-blue-500 text-white' : ''}
            ${hasPhotos ? 'border-blue-300' : 'border-gray-200'}
          `}
          onClick={() => handleDateClick(date)}
        >
          {i}
          {hasPhotos && (
            <div className="absolute -top-2 -right-2 bg-blue-500 rounded-full p-1">
              <Camera size={12} className="text-white" />
            </div>
          )}
        </div>
      );
    }
    return days;
  };

  const changeMonth = (offset) => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + offset, 1));
  };

  if (error) {
    return (
      <div className="bg-white rounded-lg shadow p-4">
        <div className="text-red-500 text-center p-4">
          <p>{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow p-4">
      <div className="flex justify-between items-center mb-4">
        <button 
          onClick={() => changeMonth(-1)} 
          className="p-2 hover:bg-gray-100 rounded-full transition-colors"
          aria-label="Mes anterior"
        >
          &lt;
        </button>
        <h2 className="text-lg font-semibold">
          {months[currentDate.getMonth()]} {currentDate.getFullYear()}
        </h2>
        <button 
          onClick={() => changeMonth(1)} 
          className="p-2 hover:bg-gray-100 rounded-full transition-colors"
          aria-label="Mes siguiente"
        >
          &gt;
        </button>
      </div>
      <div className="grid grid-cols-7 gap-1">
        {['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'].map(day => (
          <div key={day} className="text-center font-bold text-sm text-gray-600 p-2">{day}</div>
        ))}
        {isLoading ? (
          <div className="col-span-7 h-48 flex items-center justify-center">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
          </div>
        ) : (
          renderDays()
        )}
      </div>
    </div>
  );
};

export default Calendar;