import React, { useState, useEffect } from 'react';
import WeatherComponent from './weather';
import Timelapse from './Timelapse';
import { Camera, Clock, MapPin, Info, Settings, AlertCircle } from 'lucide-react';
import { WhatsAppButton } from './whatsapp';

const FeedInfoCard = ({ title, children, icon }) => (
  <div className="bg-white rounded-lg p-4 shadow-sm border border-gray-200">
    <div className="flex items-center gap-2 mb-3">
      {icon}
      <h3 className="font-semibold text-gray-800">{title}</h3>
    </div>
    {children}
  </div>
);

const LoadingState = () => (
  <div className="bg-white rounded-lg p-6 shadow-sm border border-gray-200">
    <div className="flex items-center justify-center space-x-3">
      <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-blue-500"></div>
      <p className="text-gray-600">Cargando información...</p>
    </div>
  </div>
);

const ErrorState = ({ message, user }) => (
  <div className="bg-white rounded-lg p-6 shadow-sm border border-gray-200">
    <div className="text-center space-y-4">
      <AlertCircle className="w-12 h-12 text-red-500 mx-auto" />
      <div>
        <h3 className="text-lg font-semibold text-gray-800 mb-2">
          No se pudo cargar la información
        </h3>
        <p className="text-gray-600 text-sm mb-4">
          {message || "Hubo un problema al cargar los datos del feed"}
        </p>
        <WhatsAppButton 
          message={`Hola, tengo problemas para ver la información de mi obra en LisualPro. Mi ID es ${user?.identifier}`}
          className="w-full"
        >
          Contactar Soporte
        </WhatsAppButton>
      </div>
    </div>
  </div>
);

const Dashboard = ({ user }) => {
  const [feedInfo, setFeedInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [retryCount, setRetryCount] = useState(0);
  const MAX_RETRIES = 3;

  const translateState = (state) => {
    const translations = {
      'Active': 'Activa',
      'Inactive': 'Inactiva',
      'Capture': 'Capturando',
      'Offline': 'Desconectada',
      'Disabled': 'Deshabilitada'
    };
    return translations[state] || state;
  };

  useEffect(() => {
    const fetchFeedInfo = async () => {
      if (!user?.feedID) {
        if (retryCount < MAX_RETRIES) {
          // Wait and retry if feedID isn't available yet
          setTimeout(() => {
            setRetryCount(prev => prev + 1);
          }, 2000); // Wait 2 seconds before retrying
          return;
        }
        setError('No hay ID de feed disponible');
        setLoading(false);
        return;
      }

      try {
        const apiKey = "HWN2M2mcbZoyFDTfpaskQV9TBDk0Ir8bu4XWDxJQiBE";
        const response = await fetch(
          `https://www.teleport.io/api/v2/feed-get?` +
          `feedid=${user.feedID}&` +
          `apikey=${apiKey}&` +
          `accesskey=${user.accessKey || ''}`
        );

        if (!response.ok) {
          throw new Error('Error al obtener información del feed');
        }

        const data = await response.json();
        setFeedInfo(data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching feed info:', err);
        setError(err.message);
        setLoading(false);
      }
    };

    fetchFeedInfo();
  }, [user, retryCount]);

  if (loading) {
    return <LoadingState />;
  }

  if (error) {
    return <ErrorState message={error} user={user} />;
  }

  // Check if we have all required data before rendering
  if (!user?.processedVideoUrl) {
    return null;
  }

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString('es-ES', {
      dateStyle: 'medium',
      timeStyle: 'short'
    });
  };

  return (
    <div className="space-y-4">
      <div className="grid grid-cols-1 gap-4">
        {/* Project Basic Info */}
        <FeedInfoCard title="Información General" icon={<Info className="w-5 h-5 text-blue-500" />}>
          <div className="space-y-2">
            <p className="text-sm">
              <span className="font-medium text-gray-700">Nombre:</span>{' '}
              <span className="text-gray-600">{feedInfo?.Feed?.Description?.Name || 'No disponible'}</span>
            </p>
            <p className="text-sm">
              <span className="font-medium text-gray-700">Creado:</span>{' '}
              <span className="text-gray-600">{feedInfo?.Feed?.CreatedAt ? formatDate(feedInfo.Feed.CreatedAt) : 'No disponible'}</span>
            </p>
          </div>
        </FeedInfoCard>

        {/* Camera Status */}
        <FeedInfoCard title="Estado de la Cámara" icon={<Camera className="w-5 h-5 text-blue-500" />}>
          <div className="space-y-2">
            <p className="text-sm">
              <span className="font-medium text-gray-700">Estado:</span>{' '}
              <span className={`px-2 py-1 rounded-full text-xs ${
                feedInfo?.Feed?.LiveState === 'Live' ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'
              }`}>
                {feedInfo?.Feed?.LiveState === 'Live' ? 'En vivo' : 'Inactiva'}
              </span>
            </p>
            <p className="text-sm">
              <span className="font-medium text-gray-700">Última Captura:</span>{' '}
              <span className="text-gray-600">
                {feedInfo?.Feed?.LastAcceptedFrameTime ? formatDate(feedInfo.Feed.LastAcceptedFrameTime) : 'No disponible'}
              </span>
            </p>
            <p className="text-sm">
              <span className="font-medium text-gray-700">Resolución:</span>{' '}
              <span className="text-gray-600">{feedInfo?.Feed?.Image?.ResponseFrameSize || 'No disponible'}</span>
            </p>
          </div>
        </FeedInfoCard>

        {/* Storage Info */}
        <FeedInfoCard title="Almacenamiento" icon={<Settings className="w-5 h-5 text-blue-500" />}>
          <div className="space-y-2">
            <p className="text-sm">
              <span className="font-medium text-gray-700">Fotos Almacenadas:</span>{' '}
              <span className="text-gray-600">{feedInfo?.FeedProperties?.CapacityInfo?.Frame_Perm_Count || 0}</span>
            </p>
            <p className="text-sm">
              <span className="font-medium text-gray-700">Videos Almacenados:</span>{' '}
              <span className="text-gray-600">{feedInfo?.FeedProperties?.CapacityInfo?.Video_BlobCount_Billable || 0}</span>
            </p>
          </div>
        </FeedInfoCard>
      </div>
    </div>
  );
};

export default Dashboard; 