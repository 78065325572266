import React, { useState, useEffect } from 'react';
import { Cloud, Thermometer, Wind, Droplets, Sun, Moon, Sunrise, Sunset, AlertCircle, CloudRain, CloudSnow, CloudLightning, CloudFog, MapPin, Umbrella } from 'lucide-react';
import { WhatsAppButton } from './whatsapp';

const WeatherComponent = ({ user }) => {
  const [weather, setWeather] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  const getWeatherIcon = (code) => {
    // Weather condition codes: https://openweathermap.org/weather-conditions
    if (code >= 200 && code < 300) return <CloudLightning className="w-16 h-16 text-yellow-400" />;
    if (code >= 300 && code < 400) return <CloudRain className="w-16 h-16 text-blue-300" />;
    if (code >= 500 && code < 600) return <CloudRain className="w-16 h-16 text-blue-500" />;
    if (code >= 600 && code < 700) return <CloudSnow className="w-16 h-16 text-blue-200" />;
    if (code >= 700 && code < 800) return <CloudFog className="w-16 h-16 text-gray-400" />;
    if (code === 800) return <Sun className="w-16 h-16 text-yellow-500" />;
    return <Cloud className="w-16 h-16 text-gray-400" />;
  };

  useEffect(() => {
    console.log(user.coordinates);
    const fetchWeather = async () => {
      if (!user?.coordinates?.lat || !user?.coordinates?.lng) {
        setError('Datos de ubicación no disponibles');
        setLoading(false);
        return;
      }

      try {
        const API_KEY = "d64100e9637b3557caa33b3b4d31ad82";
        // Get current weather
        const currentResponse = await fetch(
          `https://api.openweathermap.org/data/2.5/weather?lat=${user.coordinates.lat}&lon=${user.coordinates.lng}&units=metric&lang=es&appid=${API_KEY}`
        );

        // Get forecast for rain data
        const forecastResponse = await fetch(
          `https://api.openweathermap.org/data/2.5/forecast?lat=${user.coordinates.lat}&lon=${user.coordinates.lng}&units=metric&lang=es&appid=${API_KEY}`
        );

        if (!currentResponse.ok || !forecastResponse.ok) {
          throw new Error('Error al obtener datos del clima');
        }

        const currentData = await currentResponse.json();
        const forecastData = await forecastResponse.json();
        console.log(forecastData);
        setWeather({
          current: currentData,
          forecast: forecastData.list
        });
        setLoading(false);
      } catch (err) {
        setError('No se pudo obtener la información del clima');
        setLoading(false);
      }
    };

    fetchWeather();
  }, [user]);

  if (!user?.coordinates?.lat || !user?.coordinates?.lng) {
    return (
      <div className="bg-white rounded-lg shadow-lg p-6">
        <div className="text-center space-y-4">
          <MapPin className="w-16 h-16 text-gray-400 mx-auto" />
          <h3 className="text-xl font-semibold text-gray-800">
            No hay datos de ubicación disponibles
          </h3>
          <p className="text-gray-600">
            Para poder mostrarte la información del clima, necesitamos la ubicación de tu obra.
          </p>
          <div className="max-w-sm mx-auto">
            <WhatsAppButton 
              message={`Hola, no puedo ver el clima en LisualPro porque no hay ubicación configurada. Mi ID es ${user.identifier}`}
              className="w-full"
            >
              Contactar Soporte
            </WhatsAppButton>
          </div>
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64 bg-white rounded-lg shadow-lg">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 p-6 rounded-lg shadow-lg">
        <div className="flex items-center space-x-3">
          <AlertCircle className="text-red-500 w-6 h-6" />
          <p className="text-red-600 font-medium">{error}</p>
        </div>
      </div>
    );
  }

  if (!weather) return null;

  const sunrise = new Date(weather.current.sys.sunrise * 1000).toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' });
  const sunset = new Date(weather.current.sys.sunset * 1000).toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' });

  // Calculate rain data
  const currentRain = weather.current.rain?.['1h'] || 0;
  const nextHoursRain = weather.forecast.slice(0, 8).map(period => period.rain?.['3h'] || 0);
  const totalRainForecast = nextHoursRain.reduce((sum, rain) => sum + rain, 0);

  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Location and Main Weather Info */}
        <div className="space-y-4">
          <div className="flex items-center space-x-2 text-gray-600">
            <MapPin className="w-5 h-5" />
            <span className="text-sm font-medium">{user.address || 'Ubicación no disponible'}</span>
          </div>
          
          <div className="flex items-center space-x-4">
            {getWeatherIcon(weather.current.weather[0].id)}
            <div>
              <div className="text-4xl font-bold text-gray-800">{Math.round(weather.current.main.temp)}°C</div>
              <div className="text-lg text-gray-600 capitalize">{weather.current.weather[0].description}</div>
            </div>
          </div>

          {/* Rain Information */}
          {(currentRain > 0 || totalRainForecast > 0) && (
            <div className="bg-blue-50 rounded-lg p-4 mt-4">
              <div className="flex items-center space-x-2 mb-3">
                <Umbrella className="w-5 h-5 text-blue-500" />
                <span className="font-medium text-blue-800">Información de Lluvia</span>
              </div>
              <div className="space-y-2">
                <div className="flex justify-between">
                  <span className="text-gray-600">Lluvia actual:</span>
                  <span className="font-medium">{currentRain.toFixed(1)} mm/h</span>
                </div>
                <div className="flex justify-between">
                  <span className="text-gray-600">Próximas 24h:</span>
                  <span className="font-medium">{totalRainForecast.toFixed(1)} mm</span>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Weather Details */}
        <div className="grid grid-cols-2 gap-4">
          <div className="bg-gray-50 rounded-lg p-3">
            <div className="flex items-center space-x-2 text-gray-600">
              <Thermometer className="w-5 h-5" />
              <span className="text-sm">Sensación</span>
            </div>
            <div className="text-xl font-semibold text-gray-800 mt-1">
              {Math.round(weather.current.main.feels_like)}°C
            </div>
          </div>

          <div className="bg-gray-50 rounded-lg p-3">
            <div className="flex items-center space-x-2 text-gray-600">
              <Wind className="w-5 h-5" />
              <span className="text-sm">Viento</span>
            </div>
            <div className="text-xl font-semibold text-gray-800 mt-1">
              {Math.round(weather.current.wind.speed * 3.6)} km/h
            </div>
          </div>

          <div className="bg-gray-50 rounded-lg p-3">
            <div className="flex items-center space-x-2 text-gray-600">
              <Droplets className="w-5 h-5" />
              <span className="text-sm">Humedad</span>
            </div>
            <div className="text-xl font-semibold text-gray-800 mt-1">
              {weather.current.main.humidity}%
            </div>
          </div>

          <div className="bg-gray-50 rounded-lg p-3">
            <div className="flex items-center space-x-2 text-gray-600">
              <Cloud className="w-5 h-5" />
              <span className="text-sm">Nubes</span>
            </div>
            <div className="text-xl font-semibold text-gray-800 mt-1">
              {weather.current.clouds.all}%
            </div>
          </div>
        </div>

        {/* Sun Times */}
        <div className="col-span-1 md:col-span-2 grid grid-cols-2 gap-4">
          <div className="bg-gray-50 rounded-lg p-3">
            <div className="flex items-center space-x-2 text-gray-600">
              <Sunrise className="w-5 h-5" />
              <span className="text-sm">Amanecer</span>
            </div>
            <div className="text-xl font-semibold text-gray-800 mt-1">{sunrise}</div>
          </div>

          <div className="bg-gray-50 rounded-lg p-3">
            <div className="flex items-center space-x-2 text-gray-600">
              <Sunset className="w-5 h-5" />
              <span className="text-sm">Atardecer</span>
            </div>
            <div className="text-xl font-semibold text-gray-800 mt-1">{sunset}</div>
          </div>
        </div>
      </div>

      {/* Location Map Section */}
      <div className="bg-white rounded-xl shadow-lg p-4 md:p-6 mt-6">
        <h3 className="text-lg md:text-xl font-semibold text-gray-800 mb-3 md:mb-4">Esta es la dirección de tu obra</h3>
        <div className="relative w-full h-[250px] md:h-[300px] mb-3 md:mb-4">
          <iframe
            title="Project Location"
            className="w-full h-full rounded-lg"
            frameBorder="0"
            src={`https://www.openstreetmap.org/export/embed.html?bbox=${user.coordinates.lng-0.01}%2C${user.coordinates.lat-0.01}%2C${user.coordinates.lng+0.01}%2C${user.coordinates.lat+0.01}&layer=mapnik&marker=${user.coordinates.lat}%2C${user.coordinates.lng}`}
            allowFullScreen
          />
        </div>

        {/* Location Warning */}
        <div className="bg-yellow-50 p-3 md:p-4 rounded-lg">
          <div className="flex items-start">
            <AlertCircle className="w-5 h-5 text-yellow-600 mt-0.5 mr-2 md:mr-3 flex-shrink-0" />
            <div>
              <h4 className="text-sm font-medium text-yellow-800">¿La ubicación no es correcta?</h4>
              <p className="mt-1 text-xs md:text-sm text-yellow-700">
                Si la ubicación mostrada no corresponde a tu obra, por favor contacta a soporte para actualizarla.
              </p>
              <WhatsAppButton 
                message={`Hola, la ubicación de mi obra no es correcta en LisualPro. Mi ID es ${user.identifier}`}
                className="mt-3 w-full md:w-auto text-sm"
              >
                Contactar Soporte
              </WhatsAppButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WeatherComponent;