import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { toast, ToastContainer } from 'react-toastify';
import { ChevronLeft, ChevronRight, MapPin, Star } from 'lucide-react';
import { db } from './firebase-config';
import logo from './media/lisual-logo.png';
import landing1 from './media/landing1.png';
import landing2 from './media/landing2.png';
import landing3 from './media/landing3.png';
import 'react-toastify/dist/ReactToastify.css';
import './LandingPage.css';
import { FloatingWhatsAppButton } from './whatsapp';
import WarningPopup from './warningPopup';



const LandingPage = () => {
  const [keyword, setKeyword] = useState("");
  const [featuredProjects, setFeaturedProjects] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchFeaturedProjects = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "usuarios"));
        const projectsData = querySnapshot.docs
          .map(doc => ({
            id: doc.id,
            ...doc.data()
          }))
          .filter(project => project.featured && project.visible !== false);
        setFeaturedProjects(projectsData);
      } catch (error) {
        console.error("Error fetching featured projects:", error);
      }
    };

    fetchFeaturedProjects();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const usuariosRef = collection(db, "usuarios");
    const q = query(usuariosRef, where("identifier", "==", keyword));

    try {
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        navigate(`/dashboard/${keyword}`);
      } else {
        toast.warning("Acceso denegado: el Identificador no existe.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    } catch (error) {
      console.error("Error checking user:", error);
      toast.error("An error occurred. Please try again later.");
    }
  };

  const nextSlide = () => {
    if (!isTransitioning) {
      setIsTransitioning(true);
      setCurrentSlide((prev) => (prev + 1) % featuredProjects.length);
      setTimeout(() => setIsTransitioning(false), 500);
    }
  };

  const prevSlide = () => {
    if (!isTransitioning) {
      setIsTransitioning(true);
      setCurrentSlide((prev) => (prev - 1 + featuredProjects.length) % featuredProjects.length);
      setTimeout(() => setIsTransitioning(false), 500);
    }
  };


  return (
    <div>
    <WarningPopup />
    <div className="maindiv min-h-screen flex justify-center items-center py-8">
      
      <ToastContainer />
        <FloatingWhatsAppButton message="Tengo una consulta." />

      {/* White Central Container */}
      <div className="bg-white rounded-lg shadow-xl w-full max-w-4xl mx-4">
        <div className="p-8 space-y-8">
          {/* Logo & Header */}
          <div className="text-center space-y-6">
            <img src={logo} alt="Lisual Logo" className="mx-auto h-12" />
            <h1 className="text-3xl md:text-4xl font-bold text-gray-800">
              La plataforma de marketing N°1 para obras de construcción
            </h1>
            <p className="text-gray-600">
              Ingrese su identificador para acceder a su dashboard.
            </p>
          </div>

          {/* Login Form */}
          <div className="max-w-md mx-auto">
            <form onSubmit={handleSubmit} className="space-y-4">
              <input
                type="text"
                placeholder="ID"
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <button
                type="submit"
                className="w-full bg-blue-600 text-white px-6 py-2 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                Ingresar
              </button>
            </form>
            <p className="mt-4 text-center">
              Si aun no tenes tu ID, hace click{" "}
              <a
                className="text-blue-600"
                target="_blank"
                rel="noopener noreferrer"
                href={`https://wa.me/5491166644156?text=${encodeURIComponent(
                  "Hola, necesito mi ID para ingresar a Lisual."
                )}`}
              >
                aqui
              </a>
            </p>
          </div>

          {/* Featured Projects */}
          {featuredProjects.length > 0 && (
            <div className="max-w-4xl mx-auto">
              <h2 className="text-2xl font-semibold text-center text-gray-900 mb-8">
                Proyectos Destacados
              </h2>
              <div className="relative bg-white rounded-xl shadow-lg overflow-hidden">
                <div
                  className="transition-transform duration-500"
                  style={{ transform: `translateX(-${currentSlide * 100}%)` }}
                >
                  <div className="flex">
                    {featuredProjects.map((project) => (
                      <div key={project.id} className="w-full flex-shrink-0">
                        <div className="aspect-video relative bg-gray-100">
                          {project.feedID && (
                            <iframe
                              src={`//www.teleport.io/api/v2/player?feedid=${project.feedID}&playmode=liveimageloop&imageplayspeed=1fps&playframeskipinterval=day&playframecount=600`}
                              title={`Livecam - ${project.Name}`}
                              className="w-full h-full"
                            />
                          )}
                        </div>
                        <div className="p-6">
                          <div className="flex items-center gap-2 mb-4">
                            <Star className="h-5 w-5 text-yellow-500 fill-yellow-500" />
                            <span className="text-gray-600">Proyecto Destacado</span>
                          </div>
                          <div className="flex items-center gap-4 mb-4">
                            {project.logo && (
                              <img
                                src={project.logo}
                                alt={`${project.Name} logo`}
                                className="h-12 w-12 object-contain rounded-lg"
                              />
                            )}
                            <h3 className="text-xl font-bold text-gray-900">{project.Name}</h3>
                          </div>
                          <div className="flex items-center gap-2 text-gray-600">
                            <MapPin className="h-4 w-4" />
                            <p>{project.address}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                {featuredProjects.length > 1 && (
                  <>
                    <button
                      onClick={prevSlide}
                      className="absolute left-4 top-1/2 -translate-y-1/2 p-2 rounded-full bg-white/80 shadow-lg hover:bg-white transition-colors"
                    >
                      <ChevronLeft className="h-6 w-6" />
                    </button>
                    <button
                      onClick={nextSlide}
                      className="absolute right-4 top-1/2 -translate-y-1/2 p-2 rounded-full bg-white/80 shadow-lg hover:bg-white transition-colors"
                    >
                      <ChevronRight className="h-6 w-6" />
                    </button>
                  </>
                )}
              </div>
            </div>
          )}

          {/* Services Section */}
          <div className="bg-gray-50 -mx-8 -mb-8 mt-8 px-8 py-10 rounded-b-lg">
            <h2 className="text-2xl font-semibold text-gray-800 mb-8 text-center">
              Nuestros Servicios
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-6">
              <div className="flex flex-col items-center">
                <img src={landing1} alt="Camara" className="mb-2 w-9/12" />
                <h3 className="font-semibold">Camara en vivo</h3>
              </div>
              <div className="flex flex-col items-center">
                <img src={landing3} alt="Video" className="mb-2 w-9/12" />
                <h3 className="font-semibold">Contenido Ilimitado</h3>
              </div>
              <div className="flex flex-col items-center">
                <img src={landing2} alt="Ventas" className="mb-2 w-9/12" />
                <h3 className="font-semibold">Potenciamos tus ventas</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default LandingPage;